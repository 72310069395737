```markdown
<template>
  <main class="flex-1 flex flex-row buttons-group">
    <a class="button" @click="Change(-1)">
      -
    </a>
    <input class="form-input flex-1" v-model="value" style="text-align: center; background: none;">
    <a class="button" @click="Change(+1)">
      +
    </a>
  </main>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits} from 'vue';

const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: 1
  },
  min: {
    type: Number,
    default: 1
  },
  max: {
    type: Number,
    default: 100
  }
});

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val)
});

const Change = (step) => {
  let val = (parseInt(value.value) || 0) + step;
  if (val < props.min) val = props.min;
  if (val > props.max) val = props.max;
  value.value = val;
};
</script>

<style scoped>
/* Add your styles, if any */
</style>
```