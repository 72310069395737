<template>
  <router-view></router-view>
</template>

<script setup>
import {onMounted} from "vue";
import {useSettingsStore} from "@/stores/settings";
const settingsStore = useSettingsStore()
onMounted(() => {
  settingsStore.loadSettings()
})
</script>

<style>
* {
  font-family: tilda, segoe ui;
}
</style>

