import {ofetch} from "ofetch";
import {useRouter} from "vue-router/dist/vue-router";
import {useRoute} from "vue-router";
import {useFetch} from "@/composables/useFetch";

export const useFileUploader = () => {
  const route = useRoute();
  const router = useRouter();
  const fetch = useFetch()


  async function uploadFile() {
    // Создаём элемент input programmatically
    const fileInput = document.createElement('input');
    fileInput.type = 'file';

    // Делаем промис, который будет разрешён при выборе файла
    const fileSelected = new Promise((resolve, reject) => {
      fileInput.onchange = () => {
        const file = fileInput.files[0];
        if (file) {
          resolve(file);
        } else {
          reject(new Error('No file selected'));
        }
      };
    });

    // Программно вызываем диалог выбора файла
    fileInput.click();

    try {
      // Ждём, пока пользователь выберет файл
      const file = await fileSelected;

      const formData = new FormData();
      formData.append('file', file);

      const data = await fetch.request('/api/v1/files', {
        method: 'POST',
        body: formData,
      });


      // console.log('File uploaded successfully:', data);
      return data.file_url; // Возвращаем ответ от сервера
    } catch (error) {
      console.error('Error uploading the file:', error);
      throw error; // Пробрасываем ошибку, чтобы её можно было обработать выше по цепочке
    }
  }


  return {
    uploadFile,
  };
};