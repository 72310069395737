import {defineStore} from 'pinia'
import {ref, computed} from "vue";

export const useAuthStore = defineStore('auth', () => {

  const isAuthenticated = ref(false)

  const getToken = () => {
    return localStorage.getItem('store.auth.token')
  }

  const login = (token) => {
    localStorage.setItem('store.auth.token', token)
    isAuthenticated.value = true
  }
  const logout = () => {
    localStorage.setItem('store.auth.token', null)
    isAuthenticated.value = false
  }

  isAuthenticated.value = getToken() != null





  return {
    getToken,
    login,
    logout,
    isAuthenticated,
  }
})