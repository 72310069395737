<template>
  <div @mouseover="showElement('show1')" @mouseleave="hideElement('hide1')">
    <!-- Кнопка Проект -->
    <button class="button">
<!--      <svg class="svg">-->
<!--        <use href="../assets/icons.svg#bars"></use>-->
<!--      </svg>-->
      <span> Проект </span>
    </button>
    <div v-if="data.showProjectMenu" class="square" style="z-index: 200; white-space: nowrap;">
      <div class="row">
        <button class="button itembutton px15 py5">
<!--          <svg class="svg">-->
<!--            <use href="../assets/icons.svg#upload"></use>-->
<!--          </svg>-->
          <span> Загрузить проект </span>
        </button>
      </div>
      <div class="row">
        <button class="button itembutton px15 py5">
<!--          <svg class="svg">-->
<!--            <use href="../assets/icons.svg#check"></use>-->
<!--          </svg>-->
          <span> Сохранить проект </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, watch, computed } from "vue";
import '@/styles/mvp.css';
import '@/styles/mvp-button.css';

const data = reactive({
  showProjectMenu: false,
  isButtonHovered: false,
  switchState: 1
})

const getSwitchButtonStyle = (buttonNumb) => {
  const size = buttonNumb === data.switchState ? 40 : 30;
  return `width: ${size}px; height: ${size}px`;
}

const changeSwitch = () => {
  data.switchState = data.switchState === 1 ? 2 : 1;
}

const showElement = (sost) => {
  console.log(sost)
  data.showProjectMenu = true
}

const hideElement = (sost) => {
  console.log(sost)
  data.showProjectMenu = false;
}

</script>

<style>
/* Здесь можно включить ваши глобальные стили */
.positionrel {
  position: relative;
}

.jurgt {
  justify-content: flex-end;
}

.imcn {
  object-fit: contain;
}

.square {
  /* width: 100px;
  /* height: 200px; */
  overflow: visible;
  display: table-column;
  top: 52px;
  /* Отображаем элемент сразу после кнопки */
  left: 37px;
  position: absolute;


  background-color: white;
  /*border: 1px solid black;*/
  /* добавляем границу для визуализации */
}

.row {
  display: table-row;
  white-space: nowrap;
  width: 1%
}

.svg {
  width: 24px;
  height: 24px
}
</style>