<template>
  <div>
    <div>1</div>
    <div>2</div>
    <div>3</div>
    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <a href="#">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Noteworthy technology acquisitions 2021</h5>
      </a>
      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
      <a href="#" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        Read more
        <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
        </svg>
      </a>
    </div>
  </div>
<!--  <div class="grid-container">-->
<!--    <div class="worktexture"></div>-->
<!--  </div>-->
</template>

<script setup>

</script>

<style scoped>
.grid-container {
            position: relative;
            width: 100%;
            height: 100vh;
            background: white;
            overflow: hidden;
        }

        .worktexture {
            position: absolute;
            inset: 0;
            pointer-events: none;
            background-image:
                linear-gradient(to right, rgba(52, 54, 54, 0.25) 1px, transparent 1px),
                linear-gradient(to bottom, rgba(52, 54, 54, 0.25) 1px, transparent 1px),
                linear-gradient(to right, rgba(52, 54, 54, 0.35) 2px, transparent 1px),
                linear-gradient(to bottom, rgba(52, 54, 54, 0.35) 2px, transparent 1px),
                linear-gradient(to right, rgba(52, 54, 54, 0.0) 1px, transparent 1px),
                linear-gradient(to bottom, rgba(52, 54, 54, 0.0) 1px, transparent 1px);
            background-size:
                25px 25px,
                25px 25px,
                calc(25px * 16) calc(25px * 16),
                calc(25px * 16) calc(25px * 16),
                20px 20px,
                20px 20px;
            background-position: calc(25px * 4) calc(25px * 5);
            z-index: 0;
        }
</style>
