<template>
  <div
    v-if="modelValue"
    class="editor-object absolute select-none"
    :style="objectStyle"
    @pointerdown="mouseDownHandler"
    @pointerup="mouseUpHandler"
    :selected="editorStore.selected.includes(modelValue)"
    :draging="(editorStore.mode===editorModes.editorMove)||(editorStore.mode===editorModes.objectMove)"
    @wheel.stop="mouseWheelHandler"
    @pointerover="mouseOverHandler"
    @pointerout="mouseOutHandler"
    id="element"
  >
    <template v-if="editorStore.showPorts">
      <div
        component-port
        :component-port-type="port.type"
        class="port flex items-center justify-center "
        v-for="port in modelValue.component.ports" :key="port.id"
        :style="{
            zIndex: 9999,
            position: 'absolute',
            left: `${editorStore.layer === 'front' ? (port.x * 25 - port.diameter / 2.0) * editorStore.scale : (modelValue.component.width * 25 -  (port.x * 25 - port.diameter / 2.0) - 10) * editorStore.scale}px`,
            top: `${(port.y * 25 - port.diameter / 2.0) * editorStore.scale}px`,
            width: `${port.diameter * editorStore.scale}px`,
            height: `${port.diameter * editorStore.scale}px`,
            // backgroundColor: port.type === 'IN' ? 'red' : (port.type === 'OUT' ? 'black' : 'blue'),
            // backgroundColor: 'blue',
            border: '1px solid white',
            borderRadius: `${port.diameter * editorStore.scale}px`,
            opacity: 0.8,
            fontSize: `${port.diameter * 0.2 * editorStore.scale}px`,
            color: 'black'
          }"
      >{{port.type}}</div>
    </template>
    <transition name="fade" mode="out-in">
      <img v-if="editorStore.layer === 'front'"  :src="modelValue.component.front_image || '/frontend/empty.png'"
           class="w-full h-full select-none" draggable="false"/>
      <img v-else-if="editorStore.layer === 'back'" :src="modelValue.component.back_image || '/frontend/empty.png'" class="w-full h-full select-none mirror"
           draggable="false"/>
      <img v-else-if="editorStore.layer === 'cgd'" :src="modelValue.component.cgd_image || '/frontend/empty.png'" class="w-full h-full select-none"
           draggable="false"/>
    </transition>
  </div>
</template>

<script setup>

import {defineProps, defineEmits, computed} from "vue";
import {useEditorStore, editorModes} from "@/stores/editor";
import Icon from "@/components/Icon";

const editorStore = useEditorStore()

const s = computed(() => {
  return editorStore.scale
})

const props = defineProps({
  modelValue: {
    default: null,
  },
})

function a(event) {
  console.log('qwer', event)
}

const mouseOverHandler = (event) => {
  editorStore.pointer.object = props.modelValue
}


const mouseOutHandler = (event) => {
  editorStore.pointer.object = null
}


function rotateRectangle(x, y, width, height, rot) {
  // Приводим угол к диапазону [0, 360)
  rot = ((rot % 360) + 360) % 360;

  let newX = x;
  let newY = y;

  if (rot === 90) {
    // Поворот на 90 градусов
    newX = x - height;
    newY = y + width;
  } else if (rot === 180) {
    // Поворот на 180 градусов
    newX = x - width;
    newY = y - height;
  } else if (rot === 270) {
    // Поворот на 270 градусов
    newX = x + height;
    newY = y - width;
  }

  // Округляем до ближайшего значения с шагом 25 единиц
  newX = Math.round(newX / 25) * 25;
  newY = Math.round(newY / 25) * 25;

  return {x: newX, y: newY};
}


const objectStyle = computed(() => {
  const style = {}

  if (editorStore.visionMode === "ugo") {
    if (!props.modelValue.component.category.is_visible){
      console.log('не вижу')
      return style
    }
  }

  
  style.left = `${props.modelValue.left * s.value}px`
  style.top = `${props.modelValue.top * s.value}px`


  let w = 25 * props.modelValue.component.width
  let h = 25 * props.modelValue.component.height

  let ow = props.modelValue.component.width
  let oh = props.modelValue.component.height


  style.width = `${w * s.value}px`
  style.height = `${h * s.value}px`

  if (editorStore.orderChange === "front") {
    style.zIndex = `${1000 - props.modelValue.component.category.order}`
  }
  else {
    style.zIndex = `${props.modelValue.component.category.order}`
  }

  // style.zIndex = `${props.object.z}`
  // // style.zIndex = `${1000 - props.modelValue.component.category.order}`
  // style.transform = `rotate(${90}deg)`
  // style.transform = `rotate(${props.modelValue.rotation || 0}deg) translate(${-w/2}px, ${-h/2}px) `
  // style.transformOrigin = 'top left'


  const rotationAngle = props.modelValue.rotation * 90

  console.log('ROTATION: ', props.modelValue.rotation)

  // style.transform = `rotate(${rotationAngle || 0}deg) translate(${-(w-h)/2}px, ${-(h-w)/2}px)`
  style.transform = `rotate(${rotationAngle || 0}deg) `
  let dX = 0
  let dY = 0
  if ((props.modelValue.rotation || 0) % 2 === 0) {
    dY = 0
  } else {
    dX = (oh - ow) / 2.0 * 25
    dY = -(oh - ow) / 2.0 * 25
  }

  const oX = editorStore.offsetX;
  const oY = editorStore.offsetY;

  style.transform = `translate(${(dX * editorStore.scale + oX) }px, ${(dY * editorStore.scale + oY)  }px) rotate(${rotationAngle || 0}deg) `
  // style.transform = `translate(${(dX + oX) }px, ${(dY + oY)  * s.value}px) rotate(${rotationAngle || 0}deg) `

  // style.transformOrigin = 'top left'
  // style.transform = `rotate(${props.modelValue.rotation || 0}deg)  `

  return style
})


const emit = defineEmits([
  'leftButtonDown',
  'rightButtonDown',
  'mouseUp',
  'update:modelValue',
  'rotate',
])

const mouseDownHandler = (event) => {
  if (event.button === 0) {
    emit('leftButtonDown', event)
  }
  if (event.button === 2) {
    emit('rightButtonDown', event)
  }
}

const mouseUpHandler = (event) => {
  emit('mouseUp', event)
}



function rotatePoint(cx, cy, x, y, angle) {
  const radians = (Math.PI / 180) * angle;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const nx = cos * (x - cx) - sin * (y - cy) + cx;
  const ny = sin * (x - cx) + cos * (y - cy) + cy;
  return {x: nx, y: ny};
}

const rotate = (delta) => {
  console.log('rot')
  if ((editorStore.selected.length === 1) && (editorStore.selected[0] === props.modelValue )) {
    const oldRotation = props.modelValue.rotation || 0
    const rotation = (props.modelValue.rotation || 0) + delta
    const data = {
      ...props.modelValue,
      rotation: rotation
    }

    const w = props.modelValue.component.width
    const h = props.modelValue.component.height
    const r1 = (props.modelValue.rotation || 0 + 4) % 4
    const r2 = ((props.modelValue.rotation || 0) + delta + 4) % 4
    emit('update:modelValue', data)
  }

}


const mouseWheelHandler = (event) => {
  const rotationAngle = Math.trunc(event.wheelDelta / 100)
  rotate(rotationAngle)
  emit('rotate')

}



</script>

<style>
.mirror {
  /*transform: scaleX(-1); !* Отзеркалить по вертикали *!*/
}
.editor-object {

  cursor: move;
  /*transition: left .1s, top .1s, transform .5s;*/
  transition: transform .5s;
}

.editor-object > img {
  /*box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);*/
  /*filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));*/
}

.editor-object[draging="true"] {

  transition: unset;
}


/*.editor-object[selected="true"] {*/
/*}*/

.editor-object[selected="true"]:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  inset: 0;
  background: rgb(var(--selection), 0.25);
  border: 4px dashed rgb(var(--selection));
  border-radius: 9px;
}

.grid-item-controls {
  visibility: hidden;
}

.editor-object:hover .grid-item-controls {
  visibility: visible;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
  opacity: 0;
}


div[component-port] {
  background-color: white;
}
</style>