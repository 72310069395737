<template id="select-control">
  <main class="flex-1 flex flex-row ">
    <div class="relative flex flex-row flex-1 w-full" >
			<span class="relative flex flex-row flex-1 w-full" tabindex=0>
				<input class="form-input flex-1" :value="title" :placeholder="placeholder" readonly @click="toggleDropdown" >
				<a class="button button-transparent absolute right-0 pointer-events-none" >
          <Icon name="angle-down" color="black"></Icon>
				</a>
			</span>
      <div v-show="isOpen" class="absolute top-0 left-0 dropview p-[5px]" bot-left tabindex=0>
        <a class="button itembutton sm" v-for="i in options" :key="i.value" @click="SetOption(i)">
          <span>{{ i.title || i.value }}</span>
        </a>
      </div>
    </div>
  </main>
</template>

<script setup>
import {reactive, ref, onMounted, watch, defineProps, defineEmits, computed, nextTick} from 'vue';
import Icon from "@/components/Icon";



const props = defineProps({
  modelValue: [String, Number],
  options: {
    type: Array,
    required: true,
    default: () => ([{title: "test 1", value: 1}, {title: "test 2", value: 2}, {title: "test 3", value: 3}])
  },
  placeholder: {
    type: String,
    default: ''
  },
  onupdate: Function
});

const emit = defineEmits(['update:modelValue']);



const title = computed(() => {
  const item = props.options.find(i => i.value == props.modelValue);
  if (item) {
    return item.title
  }
  return "---"
})
const isOpen = ref(false);



function SetOption(i) {
  console.log('SET: ', i.value)
  emit('update:modelValue', i.value);
  closeDropdown();
}

function openDropdown() {
  isOpen.value = true;
}

function closeDropdown() {
  setTimeout(() => {
    isOpen.value = false;
  }, 50);
}

function toggleDropdown() {
  isOpen.value = !isOpen.value;
}

onMounted(() => {
});
</script>

<style >
.select-control {
  position: relative;
  display: block;
}

.select-control:not(:focus-within) > dropview {
  display: none !important;
}

.dropview                       { --bg: var(--drop-bg); --fg: var(--drop-fg); }
.dropview                       { display: block; flex-shrink: 0; position: absolute; z-index: 1; text-align: left; }
.dropview                       { border-radius: calc(var(--r) + 3px); background: rgb(var(--drop-bg)); color: rgb(var(--drop-fg)); }
.dropview                       { box-shadow: 0 0px 10px rgba(0, 10, 20, 0.15); }
.dropview[bot-left]             { top: 100%; right: 0; margin-top: -1px; margin-right: -0px; }
.dropview[bot-right]            { top: 100%; left: 0; margin-top: -1px; margin-left: -10px; }
.dropview[top-left]             { bottom: 100%; right: 0; margin-bottom: -1px; }
.dropview[left-bot]             { top: 0; right: 100%; margin-right: -1px; }
.dropview[left-top]             { bottom: 0; right: 100%; margin-right: -1px; }

dropdown                       { position: relative; display: block; }
dropdown:not(:hover)  > dropview   { display: none !important; }
dropdown:focus-within > dropview   { display1: block !important; }
dropdown:hover        > dropview   { z-index: 3 !important; }


</style>
