<template>
  <!-- <div class="switch-control flex items-center cursor-pointer " @click="changeSwitch"> -->
  <div class="switch-control flex items-center cursor-pointer gap-[5px] items-center select-none" >
    <div class="object-contain flex items-center" :style="getSwitchButtonStyle(0)" @click="changeSwitch(0)">НИЗ</div>
    <div class="object-contain flex items-center" :style="getSwitchButtonStyle(1)" @click="changeSwitch(1)">ВЕРХ</div>
    <div class="object-contain flex items-center" :style="getSwitchButtonStyle(2)" @click="changeSwitch(2)">УГО</div>
<!--    <img class="object-contain" src="../img/switch-1.png" :style="getSwitchButtonStyle(0)" @click="changeSwitch(0)">-->
<!--    <img class="object-contain" src="../img/switch-1.png" :style="getSwitchButtonStyle(1)" @click="changeSwitch(1)">-->
<!--    <img class="object-contain" src="../img/switch-2.png" :style="getSwitchButtonStyle(2)" @click="changeSwitch(2)">-->
  </div>
</template>

<script setup>
import {onMounted, reactive, watch} from "vue";
import {useEditorStore} from "@/stores/editor";

let center = null

const editorStore = useEditorStore()
const data = reactive({
  switchState: 1,
})

onMounted(() => {
  editorStore.layer = "front"
  editorStore.orderChange = "front"
  editorStore.visionMode = "front"
})

watch(() => data.switchState, (state) => {
  if (state === 0){  // НИЗ
    editorStore.layer = "back"
    editorStore.orderChange = "back"
    editorStore.visionMode = "back"
  }
  if (state === 1){  // ВЕРХ
    editorStore.layer = "front"
    editorStore.orderChange = "front"
    editorStore.visionMode = "front"
  }
  if (state === 2){  // УГО
    editorStore.layer = "cgd"
    editorStore.orderChange = "front"
    editorStore.visionMode = "cgd"
  }
})

const getSwitchButtonStyle = (buttonNumb) => {
  const size = buttonNumb === data.switchState ? 35 : 35;
  const scale = buttonNumb === data.switchState ? 1.0 : 0.7;
  const color = buttonNumb === data.switchState ? '#177e6c' : 'white';
  return {
    width: `${size}px`,
    height: `${size}px`,
    transform: `scale(${scale})`,
    color: color,
  }
}

function changeSwitch(state) {
  console.log('State: ', state)
  if (data.switchState === state){
    return
  }
  console.log('SWATES: ', state, data.switchState)
  if (((state === 0) && (data.switchState === 1)) || ((state === 1) && (data.switchState === 0))|| ((state === 2) && (data.switchState === 0))|| ((state === 0) && (data.switchState === 2))) {
  // if ([0,1].includes(state)) {
    if (center === null) {
      center = 0.0

      for (const object of editorStore.objects) {
        center += (object.left  + object.component.width / 2.0 * 25) / editorStore.objects.length
      }

      // Выравнивание по левой границе
      center = Math.trunc(center / 25) * 25

    }



    for (const object of editorStore.objects) {
      const Xr = (object.left + object.component.width * 25) - center
      const Xn = center - Xr
      object.left = Xn
    }

  }
  data.switchState = state

}
// const changeSwitch = () => {
//   data.switchState = data.switchState === 1 ? 2 : 1;
// }
</script>

<style scoped>
.switch-control {
  border-radius: var(--r);
}
.switch-control:hover {
  background: rgb(var(--ag), 0.5);
}
</style>