<template>
  <div class="window window-scrollable-content absolute top-0 left-0" id="unit-editor-window"
       style="
			z-index: 101; margin-left: 365px; width: auto; max-width: 100%; padding-top: 20px; padding-bottom: 55px;
			max-height: 100%;
			filter: drop-shadow(0 0px 5px rgb(0,33,64, 0.75));
		"
     @wheel.stop
  >
    <main class="window-background">
      <header class="window-title">
        <span class="window-name flex-1 px-2.5"> {{palleteStore.form.creating ? 'Создание' : 'Редактирование'}}</span>

        <div v-if="!palleteStore.form.creating" class="flex flex-row items-center w-[195px]">
          <a class="button button-delete"  title="Удалить" @click="remove">
            <Icon name="trash" color="white"></Icon>
          </a>
          <i class="opacity-50 ml-[10px]"> Удалить </i>
        </div>

        <a class="button button-transparent" @click="palleteStore.form.selected = null">
          <Icon name="cross" color="white"></Icon>
        </a>
      </header>
      <form class="window-content flex flex-row  " style="padding: 0; position: relative; align-items: stretch; overflow: hidden " onsubmit="return false;">

        <aside class="relative flex flex-col" style="width: 370px; min-height: 420px">
          <component-preview
            :front-image="palleteStore.form.selected.front_image || '/frontend/empty.png'"
            :middle-image="palleteStore.form.selected.cgd_image || '/frontend/empty.png'"
            :back-image="palleteStore.form.selected.back_image || '/frontend/empty.png'"
            :width="palleteStore.form.selected.width"
            :height="palleteStore.form.selected.height"
            :ports="palleteStore.form.selected.ports"
          ></component-preview>
          <!--          <elementpreview-control item="-item"></elementpreview-control>-->

          <div class="flex flex-col items-center absolute" style="bottom: 0; left: 0; right: 0; margin-bottom: 15px;">

            <div class="flex flex-row items-end justify-end  gap-[15px]" style="font-family: monospace; border-radius: var(--r);">

              <div class="flex flex-row">
                <div v-if="!palleteStore.form.selected.front_image" class="button button-background"  style="background: #fff; border-radius: var(--r);">
                  <span class="opacity-80"> ВЕРХ </span>
                  <a class="button button-positive-bordered"  title="Загрузить файл" @click="uploadFrontImage">
                    <Icon name="image" color="rgb(23,126,108)"></Icon>
                  </a>
                </div>
                <div v-else class="button button-background"  style="background: #fff; border-radius: var(--r);">
                  <span>
										<img class="w-[18px] h-[18px] rounded-[5px]" :src="palleteStore.form.selected.front_image || '/frontend/empty.png'">
									</span>
                  <a class="button button-positive"  title="Удалить файл" @click="palleteStore.form.selected.front_image = null">
                    <Icon name="backspace"></Icon>
                  </a>
                </div>
              </div>
              <div class="flex flex-row ">
                <div v-if="!palleteStore.form.selected.cgd_image" class="button button-background" style="background: #fff; border-radius: var(--r);">
                  <span class="opacity-80"> УГО </span>
                  <a class="button button-positive-bordered" title="Загрузить файл" @click="uploadMiddleImage">
                    <Icon name="image" color="rgb(23,126,108)"></Icon>
                  </a>
                </div>
                <div v-else class="button button-background"  style="background: #fff; border-radius: var(--r);">
									<span>
										<img class="w-[18px] h-[18px] rounded-[5px]" :src="palleteStore.form.selected.cgd_image">
									</span>
                  <a class="button button-positive"  title="Удалить файл" @click="palleteStore.form.selected.cgd_image = null">
                    <Icon name="backspace" ></Icon>
                  </a>
                </div>
              </div>
              <div class="flex flex-row ">
                <div v-if="!palleteStore.form.selected.back_image" class="button button-background" style="background: #fff; border-radius: var(--r);">
                  <span class="opacity-80"> НИЗ </span>
                  <a class="button button-positive-bordered" title="Загрузить файл" @click="uploadBackImage">
                    <Icon name="image" color="rgb(23,126,108)"></Icon>
                  </a>
                </div>
                <div v-else class="button button-background"  style="background: #fff; border-radius: var(--r);">
									<span>
										<img class="w-[18px] h-[18px] rounded-[5px]" :src="palleteStore.form.selected.back_image">
									</span>
                  <a class="button button-positive"  title="Удалить файл" @click="palleteStore.form.selected.back_image = null">
                    <Icon name="backspace" ></Icon>
                  </a>
                </div>
              </div>
            </div>

            <div class="flex flex-row justify-center space-x-[15px] px-[15px] mt-[15px]" style="width: 100%">
              <div class="w-[110px]">
                <label class="block text-center mb-[5px]"> Ширина </label>
                <div style="background: rgb(var(--bg)); border-radius: var(--r);">
                  <step-number-input v-model="palleteStore.form.selected.width"></step-number-input>
                </div>
              </div>
              <div class="w-[110px]">
                <label class="block text-center mb-[5px]"> Высота </label>
                <div style="background: rgb(var(--bg)); border-radius: var(--r);">
                  <step-number-input v-model="palleteStore.form.selected.height"> </step-number-input>
                </div>
              </div>
            </div>

          </div>
        </aside>

        <aside class="p-[35px] pb-[20px] space-y-[5px] max-h-[75vh] overflow-auto" style="width: 270px" @wheel.stop>
          <div class="form-field">
            <ui-select
              :options="palleteStore.categories.map(c => ({title: c.name, value: c}))"
              v-model="palleteStore.form.selected.category"

            ></ui-select>
<!--            <select-control config="-{-->
<!--							placeholder: 'Категория',-->
<!--							prop: prop(item, 'category'),-->
<!--							options: editor_getCategories(),-->
<!--							onupdate: () => editor_updateCategory(item),-->
<!--						}"-->
<!--            ></select-control>-->
          </div>
          <div class="form-field">
            <div class="flex flex-row " >
              <div v-if="!palleteStore.form.selected.cover_image" class="button button-background" style="background: #fff; border-radius: var(--r); border: 1px solid #999999">
                <span class="opacity-80"> Обложка </span>
                <a class="button button-positive-bordered" title="Загрузить файл" @click="uploadCoverImage">
                  <Icon name="image" color="rgb(23,126,108)"></Icon>
                </a>
              </div>
              <div v-else class="button button-background"  style="background: #fff; border-radius: var(--r); border: 1px solid #999999">
									<span>
										<img class="w-[18px] h-[18px] rounded-[5px]" :src="palleteStore.form.selected.cover_image">
									</span>
                <a class="button button-positive"  title="Удалить файл" @click="palleteStore.form.selected.cover_image = null">
                  <Icon name="backspace" ></Icon>
                </a>
              </div>
            </div>
          </div>
          <div class="form-field">
            <input class="form-input w-full" placeholder="Название" v-model="palleteStore.form.selected.name">
          </div>
          <div class="form-field">
            <input class="form-input w-full" placeholder="Описание" v-model="palleteStore.form.selected.description">
          </div>

          <template v-for="parameter in palleteStore.form.selected.category.parameters" :key="parameter.id">
            <div v-if="parameter.type === 'STRING'" class="form-field">
              <input class="form-input w-full" :placeholder="parameter.name" v-model="palleteStore.form.selected.parameters[parameter.id]">
            </div>
            <div v-else-if="parameter.type === 'MD_TEXT'" class="form-field">
              <textarea class="form-input w-full max-h-[300px] min-h-[100px]"  :placeholder="parameter.name" v-model="palleteStore.form.selected.parameters[parameter.id]"></textarea>
            </div>
            <div v-else-if="parameter.type === 'NUMBER'" class="form-field flex whitespace-nowrap items-center" >
              <label class="form-label w-[120px] text-ellipsis overflow-hidden"> <b>{{parameter.name}}</b>  {{parameter.unit}} </label>
              <input class="form-input" type="number" placeholder="0" v-model="palleteStore.form.selected.parameters[parameter.id]">
            </div>
            <div v-else-if="parameter.type === 'BOOLEAN'" class="flex select-none gap-1">
              <input type="checkbox" v-model="palleteStore.form.selected.parameters[parameter.id]" />
              <label >{{parameter.name}}</label>
            </div>
          </template>

          <div>
            <div class="mt-2 mb-1 flex items-center justify-between">
              <div>Порты</div>
              <button class="button button-positive w-min h-[40px] clipshape-all" @click="addNewPort">
                <span> + </span>
              </button>
            </div>
            <div v-for="port in palleteStore.form.selected.ports" :key="port.id" class="gap-2 flex-col flex clipshape-all bg-[#eeeeee] p-2 mb-2">
              <div class="flex items-center justify-between">
                <div>Порт</div>
                <a class="button button-transparent" @click="removePort(port)">
                  <Icon name="cross" color="black"></Icon>
                </a>
              </div>

              <div  class="form-field flex whitespace-nowrap items-center" >
                <label class="form-label w-[120px] text-ellipsis overflow-hidden"> <b>Тип</b>   </label>
                <ui-select
                  class="w-[108px]"
                  :options="[{title: 'IN', value: 'IN'}, {title: 'OUT', value: 'OUT'}, {title: 'IN/OUT', value: 'IN_OUT'}, ]"
                  v-model="port.type"
                ></ui-select>
              </div>

              <div  class="form-field flex whitespace-nowrap items-center" >
                <label class="form-label w-[120px] text-ellipsis overflow-hidden"> <b>X</b>   </label>
                <input class="form-input" type="number" placeholder="0" v-model="port.x" >
              </div>
              <div  class="form-field flex whitespace-nowrap items-center" >
                <label class="form-label w-[120px] text-ellipsis overflow-hidden"> <b>Y</b>   </label>
                <input class="form-input" type="number" placeholder="0" v-model="port.y">
              </div>
              <div  class="form-field flex whitespace-nowrap items-center" >
                <label class="form-label w-[120px] text-ellipsis overflow-hidden"> <b>Диаметр</b>   </label>
                <input class="form-input" type="number" placeholder="5" v-model="port.diameter">
              </div>

            </div>
          </div>

          <div class="flex flex-row pt-[10px]">
            <span class=""></span>
            <button class="button button-positive w-full h-[40px] clipshape-all" @click="save">
              <span> Сохранить </span>
            </button>
          </div>
        </aside>
      </form>
    </main>
    <br>
  </div>
</template>

<script setup>

import StepNumberInput from "@/components/StepNumberInput";
import ComponentPreview from "@/components/ComponentPreview";
import {usePalleteStore} from "@/stores/pallete";
import {computed, ref} from "vue";
import {mapState} from "pinia";
import Icon from "@/components/Icon";
import UiSelect from "@/components/UiSelect";
import {useFetch} from "@/composables/useFetch";
import {useFileUploader} from "@/composables/useFileUploader";


const palleteStore = usePalleteStore()
const fetch = useFetch()
const fileUploader = useFileUploader()

const uploadFrontImage = async (event) => {
  const fileUrl = await fileUploader.uploadFile()
  palleteStore.form.selected.front_image = fileUrl
  console.log('UPLOADED FILE: ', fileUrl)
}

const uploadMiddleImage = async (event) => {
  const fileUrl = await fileUploader.uploadFile()
  palleteStore.form.selected.cgd_image = fileUrl
  console.log('UPLOADED FILE: ', fileUrl)
}

const uploadBackImage = async (event) => {
  const fileUrl = await fileUploader.uploadFile()
  palleteStore.form.selected.back_image = fileUrl
  console.log('UPLOADED FILE: ', fileUrl)
}

const uploadCoverImage = async (event) => {
  const fileUrl = await fileUploader.uploadFile()
  palleteStore.form.selected.cover_image = fileUrl
  console.log('UPLOADED FILE: ', fileUrl)
}

const removePort = (port) => {
  const idx =  palleteStore.form.selected.ports.indexOf(port)
  if (idx >= 0) {
    palleteStore.form.selected.ports.splice(idx, 1)
  }
}

const addNewPort = () => {
  palleteStore.form.selected.ports.push({
    type: 'IN_OUT',
    x: 0,
    y: 0,
    diameter: 10,
  })
}

const remove = async (event) => {
  if (palleteStore.form?.selected?.id) {
    fetch.request(`/api/v1/components/${palleteStore.form?.selected?.id}`, {
      method: 'DELETE'
    }).then(() => {
      palleteStore.form.selected = null
    }).finally(() => {
      palleteStore.getComponents()
    })

  }
}


const save = () => {
  console.log('Save form: ', palleteStore.form.selected)

  const form = {
    ...palleteStore.form.selected
  }
  form.category = form.category.id
  console.log('PORTS: ', form.ports)
  const url = palleteStore.form.creating ? '/api/v1/components/' : `/api/v1/components/${form.id}`
  console.log('Saved form: ', form)
  fetch.request(url, {
    method: palleteStore.form.creating ? 'POST' : 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(form)
  }).then(data => {
    palleteStore.form.selected = null
  }).finally(() => {
    palleteStore.getComponents()
  })
}


</script>

<style scoped>

</style>
