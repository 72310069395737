import { createApp } from 'vue'
import App from './App.vue'
import {createPinia} from 'pinia'
import router from './router'
import tooltipDirective from './directives/tooltip'; // Импортируем директиву

// import './styles/mvp.css'
import './styles/mvp-button.css'
import './styles/mvp-windows.css'
import './styles/mvp-base.css'
import './styles/mvp-dropdown.css'
import './styles/mvp-forms.css'
import './styles/mvp-input.css'
import './styles/mvp-scrollbars.css'
import './styles/mvp-theme.css'

import './assets/tailwind.css'

import './assets/styles/global.css'; // Путь к вашему глобальному стилю


const pinia = createPinia()

const app = createApp(App)
  .use(router)
  .use(pinia)


app.directive('tooltip', tooltipDirective);

app.mount('#app')
