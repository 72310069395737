<template id="elementpreview-control">
  <main class="elementpreview">
    <i x-effect="item.w + item.h; xunwatch(); Resize();"></i>

    <div :style="{ '--x': `${imgX}px`, '--y': `${imgY}px`, '--step': `${zoomStep}px` }">
      <div class="preview-background"></div>


      <div
        class="flex items-center justify-center"
        v-for="port in ports" :key="port.id"
        :style="{
          zIndex: 9999,
          position: 'absolute',
          left: `${imgX + port.x * zoomStep - port.diameter / 2.0}px`,
          top: `${imgY + port.y * zoomStep - port.diameter / 2.0}px`,
          width: `${port.diameter}px`,
          height: `${port.diameter}px`,
          // backgroundColor: port.type === 'IN' ? 'red' : (port.type === 'OUT' ? 'black' : 'blue'),
          backgroundColor: 'white',
          border: '1px solid white',
          borderRadius: `${port.diameter}px`,
          opacity: 0.8,
          fontSize: `${port.diameter * 0.2}px`,
          color: 'black'
        }"
      >
        {{port.type}}
      </div>
      <img
        :src="frontImage"
        class="preview-img2"
        :style="{
          position: 'absolute', 
          transition: 'none 0.15s linear', 
          transitionProperty: 'top, left, width, height',
          width: `${imgW}px`, 
          height: `${imgH}px`, 
          left: `${imgX}px`, 
          top: `${imgY}px`,
          filter: 'drop-shadow(0 5px 3px rgba(0,0,0,0.35))'
        }"
      />
      <img
        v-if="middleImage"
        :src="middleImage"
        class="preview-img2"
        :style="{
          position: 'absolute', 
          transition: 'none 0.15s linear', 
          transitionProperty: 'top, left, width, height',
          width: `${imgW}px`, 
          height: `${imgH}px`, 
          left: `calc(${imgX}px + var(--step))`, 
          top: `calc(${imgY}px + var(--step))`,
          filter: 'drop-shadow(0 5px 3px rgba(0,0,0,0.35))'
        }"
      />
      <img
        v-if="backImage"
        :src="backImage"

        class="preview-img2"
        :style="{
          position: 'absolute',
          transition: 'none 0.15s linear',
          transitionProperty: 'top, left, width, height',
          width: `${imgW}px`,
          height: `${imgH}px`,
          left: `calc(${imgX}px + 2*var(--step))`,
          top: `calc(${imgY}px + 2*var(--step))`,
          filter: 'drop-shadow(0 0 4px rgba(0,0,0,0.4))'
        }"
      />
    </div>
  </main>
</template>

<script setup>
import {ref, onMounted, defineProps, watch} from 'vue';


const props = defineProps({
  frontImage: {
    default: null,
  },
  middleImage: {
    default: null,
  },
  backImage: {
    default: null,
  },
  width: {
    default: 3 ,
  },
  height: {
    default: 3 ,
  },
  ports: {
    default: () => ([])
  }
})

// const item = ref({});
const imgW = ref(50);
const imgH = ref(50);
const zoomStep = ref(25);
const imgX = ref(50);
const imgY = ref(25);

function Resize() {
  // const newItem = item.value;
  let width = (props.width || 3) * 25;
  let height = (props.height || 3) * 25;


  console.log('1: ', width, height)

  const max = Math.max(width, height, 1);
  let zoom = 240 / max;
  if (zoom > 1.54) zoom = 1.54;

  console.log('2: ', max, zoom)


  zoomStep.value = 25 * zoom;

  imgW.value = (props.width || 3) * zoomStep.value;
  imgH.value = (props.height || 3) * zoomStep.value;

  const spacew = 390 * (1 / zoom);
  const spaceh = 290 * (1 / zoom);



  console.log('IMGXY: ', imgX.value, imgY.value)
}

onMounted(() => {
  Resize();
});


watch(() => props.width + props.height, () => {
  console.log(123)
  Resize()
})

function database_img(item, index) {
  // Sample implementation for database_img function
  return `path_to_image_${index}.png`;
}
</script>
<style>
.elementpreview {
  position: relative;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
  background: rgb(var(--ag), 0.4);
}
.preview-img2:hover {
  z-index: 1;
}
.preview-background {
  position: absolute;
  inset: 0;
  background-image:
    linear-gradient(to right, rgb(var(--fg), 0.25) 1px, transparent 1px),
    linear-gradient(to bottom, rgb(var(--fg), 0.25) 1px, transparent 1px),
    linear-gradient(to right, rgb(var(--fg), 0.35) 2px, transparent 1px),
    linear-gradient(to bottom, rgb(var(--fg), 0.35) 2px, transparent 1px);
  background-size:
    var(--step) var(--step),
    var(--step) var(--step),
    calc(var(--step) * 16) calc(var(--step) * 16),
    calc(var(--step) * 16) calc(var(--step) * 16);
  background-position: var(--x) calc(25px * 1);
  transition: background-size 0.15s linear;
}
</style>
