<template>
<div style="pointer-events: none; display: flex; justify-content: center;">
  <div class="window-restyle-wrapper absolute bottom-0  flex items-end justify-center mb-[25px]" style="z-index: 80; pointer-events: all;">
    <div class="window window-restyle" style="filter: shadow;">
     <main v-if="selected" @wheel.stop class="window-background w-[400px]" style="flex-grow: 0;">
      <header class="window-title flex items-center">
				<span class="window-name font-normal flex-1" style="line-height: 1.3">
					{{ selected.name }}
				</span>

        <a class="button button-transparent  top-0 right-0 m-[5px]" @click="close()">
          <!-- <Icon name="^" v-if="visible"></Icon>
          <Icon name="v" v-else></Icon> -->
          <Icon  v-tooltip="'Развернуть'" v-if="visible" name="angle-up"></Icon>
          <Icon  v-tooltip="'Свернуть'" v-else name="angle-down"></Icon>
        </a>
      </header>
      
      <div id="content" class="window-content overflow-y-auto max-h-80 space-y-[15px] max-h-[75vh] flex flex-row justify-between hidden" style="flex: auto; flex-shrink: 1">
        <button @click="prev()" class="pr-[10px]">&lt;</button>
        <p class="markdown" style="margin: 0;" v-html="marked.parse(selected?.content || '')"> </p>
        <button @click="next()" class="pl-[10px] m-[0px]" style="margin: 0;">&gt;</button>
      </div>

    </main>
  </div>
  </div>
</div>
</template>

<script setup>
import {marked} from "marked";
import {ref, computed} from 'vue';
import {useFetch} from "@/composables/useFetch";
import {usePalleteStore} from "@/stores/pallete";
import {useEditorStore} from "@/stores/editor";
import {usePopUpsStore} from "@/stores/popups";
import Icon from "@/components/Icon"; // Replace with actual import if needed
const fetch = useFetch()

const palleteStore = usePalleteStore()
const editorStore = useEditorStore()
const popUpsStore = usePopUpsStore()

let visible = ref(true)
let hints = ref([])
let selected = ref({})

fetch.request(`/api/v1/hints/`, {
  })
  .then((data) => {
    hints.value = data
    if (hints.value != []){
      selected.value = hints.value[0]
    }
  })
  .catch((error) => {
    console.error(error);
  })

function close(){
  const contentElement = document.getElementById('content');
  if (visible.value) {
    contentElement.classList.remove('hidden');
    visible.value = false
  }
  else { 
    contentElement.classList.add('hidden'); 
    visible.value = true
  } 
}

function prev(){
  let index = hints.value.indexOf(selected.value)
  if (index != 0){
    selected.value = hints.value[index-1]
  }
}
function next(){
  let index = hints.value.indexOf(selected.value)
  if (index != hints.value.length-1){
    selected.value = hints.value[index+1]
  }
}


</script>


<style scoped>
.markdown >>> a {
  text-decoration: underline;
  color: blue;
}
</style>