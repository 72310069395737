import {defineStore} from 'pinia'
import {useFetch} from "@/composables/useFetch";


export const usePalleteStore = defineStore('pallete', {
  state: () => ({
    components: [],
    categories: [],

    form: {
      selected: null, // Выбранный компонент
      creating: false, // Режим создания а не редактирования
    },
    filters: {},
  }),
  _actions: {
    async getCategories() {
      const fetch = useFetch();
      return fetch.request('/api/v1/categories/').then((data) => {
        this.categories = data;
      });
    },

    async getComponents(query = null) {
      const fetch = useFetch();
      console.log('this.filters', this.filters);
      console.log('query', query);
      const params = Object.fromEntries(Object.entries(this.filters));
      // console.log('...this.filters', ...params);
      return fetch.request('/api/v1/components/', {
        params: {
          // ...this.filters,
          ...params,
          query: query ? query : undefined,
        }
      }).then((data) => {
        this.components = data;
        console.log('getComponents', this.components)
      });

    }
  },
  get actions() {
    return this._actions;
  },
  set actions(value) {
    this._actions = value;
  },
})