import {defineStore} from 'pinia'
import {computed, reactive, ref} from "vue";
import {useFetch} from "@/composables/useFetch";

function getCurrentDateFormatted() {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0'); // Получаем день и добавляем 0 спереди, если нужно
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Получаем месяц (0-месяцы), добавляем 0 спереди
  const year = date.getFullYear(); // Получаем полный год

  return 'проект от ' + `${day}_${month}_${year}`; // Формируем строку с датой
}
export const usePopUpsStore = defineStore('popups', () => {

  const saveProject = reactive({
    visible: false, // его видимость
    filename: getCurrentDateFormatted(), // его данные, может быть больше полей у других попапов
  })

  const cartRedirect = reactive({ // Появляется при попытке перехода в тильду через нажатие на корзину
    visible: false, // его видимость
  })

  const cartRedirectPortValid = reactive({ // Появляется при попытке перехода в тильду через нажатие на корзину
    visible: false, // его видимость
  })

  const notAvailableComponents = reactive({ // Появляется при попытке перехода в тильду при отсутствующих в наличии компонентах
    visible: false, // его видимость
  })

  const tildaSyncResult = reactive({ // Появляется после синхронизации с тильдой
    visible: false, // его видимость
    count: 0, // его видимость
  })

  const warningsNumber = computed(() => {
    let c = 0
    if (cartRedirect.visible) {
      c++
    }
    if (cartRedirectPortValid.visible) {
      c++
    }
    if (notAvailableComponents.visible) {
      c++
    }
    return c
  })



  return {
    saveProject,
    cartRedirect,
    cartRedirectPortValid,
    notAvailableComponents,
    tildaSyncResult,
    warningsNumber,
  }
})