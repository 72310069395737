import {defineStore} from 'pinia'
import {ref} from "vue";
import {useFetch} from "@/composables/useFetch";


export const useSettingsStore = defineStore('settings', () => {
  const useFlags = ref(true)
  const fetch = useFetch()
  const loadSettings = () => {
    fetch.request('/api/v1/settings').then((data) => {
      console.log('!!! DATA: ', data)
      useFlags.value = data.show_flags
    })
  }
  return {
    useFlags,
    loadSettings,
  }
})