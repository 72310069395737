import { defineStore } from 'pinia'
import {computed} from "vue";

export const editorModes = {
  objectMove: "objectMove",
  objectPointerDown: "objectPointerDown",
  editorPointerDown: "editorPointerDown", // Нажата клавиша над рабочей областью
  editorMove: "editorMove", // Перетаскивание рабочей зоны
  selectObjects: "selectObjects", // selectObjects с помощью рамки
}

export const useEditorStore = defineStore('editor', {
  state: () => ({
    offsetX: 0,
    offsetY: 0,
    scale: 1.0,

    objects: [],
    movingObject: null,
    newObject: false, // флаг нового элемента для отмены перетаскиваия на esc
    selected: [], // Выбранный элемент / элементы
    selectionWindow: {
      left: -100000,
      top: -1000000,
      width: 0,
      height: 0,
    },
    dragdrop: {
      object: null,
      startLeft: 0,
      startTop: 0,
      objectStartLeft: 0,
      objectStartTop: 0,
      objectsStartLeft: [],
      objectsStartTop: [],
    },
    pointer: {
      object: null, // объект на который наведен курсор
    },

    mode: null,
    zCounter: 0,

    layer: "front", //"front", "back"

    tmpComponent: null,
    tmpObject: null,
    orderChange: "top", // "top", "bottom" - определение отображения ВЕРХ или НИЗ 
    visionMode: "top", // "top", "bottom", "ugo" - режим просторга ВЕРХ НИЗ УГО

    showPorts: true, // Отображает порты на объектах
    portsValid: true, // Валидны ли соединения портов



  }),
  getters: {
    notAvailableComponents(state){
      const items = []
      for (const object of state.objects) {
          if (object.component.in_stock) {
            continue
          }
        let found = false
        for (const item of items) {
          if (item.id == object.component.id) {
            found = true
            break
          }
        }
        if (!found) {
          items.push(object.component)
        }
      }
      return items
    }
  }

})