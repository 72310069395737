<template>
  <div class="text-center px-10">

    <p class="text-white window-name px-0">Данный сайт не поддерживается в мобильной версии. Пожалуйста, зайдите с компьютера или планшета.</p>
  </div>
</template>


<script setup>

</script>

<style scoped>

.mobile-only {
  display: none;
}
@media (max-width: 768px) {
  .mobile-only {
    display: block;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .mobile-only {
    display: none;
  }
}
</style>