// src/directives/tooltip.js

// Создаем директиву для тултипа
const tooltipDirective = {
  mounted(el, binding) {
    const tooltip = document.createElement('div');
    tooltip.className = 'tooltip';
    tooltip.innerText = binding.value;

    el.style.position = 'relative'; // Убедимся, что родитель имеет относительное позиционирование

    let tooltipTimeout; // Переменная для хранения идентификатора таймера

    const showTooltip = () => {
      tooltipTimeout = setTimeout(() => {
        document.body.appendChild(tooltip);
        const { top, left, width } = el.getBoundingClientRect();
        tooltip.style.position = 'absolute';
        tooltip.style.top = `${top + window.scrollY - tooltip.offsetHeight}px`;
        tooltip.style.left = `${left + window.scrollX + width / 2 - tooltip.offsetWidth / 2}px`;
      }, 500); // Задержка 2 секунды
    };

    const hideTooltip = () => {
      clearTimeout(tooltipTimeout); // Отменяем таймер, если курсор покидает элемент
      if (tooltip.parentNode) {
        document.body.removeChild(tooltip);
      }
    };

    el.addEventListener('mouseenter', showTooltip);
    el.addEventListener('mouseleave', hideTooltip);

    // Удаляем тултип при перемонтировании компонента
    el._tooltip = tooltip; // Сохраним тултип как свойство элемента
    el._hideTooltip = hideTooltip; // Сохраним функцию hideTooltip для использования в unmounted

    // Убираем слушатели событий в unmounted
    el.$destroyTooltip = () => {
      hideTooltip();
      el.removeEventListener('mouseenter', showTooltip);
      el.removeEventListener('mouseleave', hideTooltip);
    };
  },
  beforeUnmount(el) {
    // Удаляем тултип и события перед размонтированием
    el.$destroyTooltip();
  },
  unmounted(el) {
    // Здесь также можно удалить события, если они были добавлены
    delete el._tooltip;
    delete el._hideTooltip;
    delete el.$destroyTooltip;
  }
};

// Экспортируем директиву
export default tooltipDirective;