import {createRouter, createWebHistory} from 'vue-router';
import MainPage from "@/pages/MainPage"
import EditorPage from "@/pages/EditorPage"
import Test from "@/pages/Test"
import Authorization from "@/pages/Authorization"
// import Pallete from "@/components/old/Pallete"
// import Editor from "@/components/old/Editor"
const routes = [
    {
        path: '',
        component: EditorPage,
        meta: { title: 'Конструктор заказа' },
        name: 'editorpage'
    },
    {
        path: '/signin',
        name: 'signin',
        component: Authorization,
      },
    {
        path: '/mainpage',
        component: MainPage,
    },
    {
        path: '/test',
        component: Test,
    },
    // {
    //     path: '/pallete',
    //     component: Pallete,
    // },
    // {
    //     path: '/editor',
    //     component: Editor,
    // },
    
    // {
    //     path: '',
    //     component: DefaultLayout,
    //     children: [
    //         {
    //             path: '/mainpage',
    //             component: MainPage,
    //         },
    //         {
    //             path: '/workspace',
    //             component: Workspace,
    //         },
    //         {
    //             path: '/test',
    //             component: Test,
    //         },
    //     ]
    // },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})
export default router