<template>
  <div ref="buttonElement" class="flex flex-row justify-end px-[20px] ">
    <a class="button" @mouseover="opened = true" @mouseleave="opened = false">
      <Icon name="bars" class="ml-[6px]"></Icon>
      <!--        <svg class="svgicon">-->
      <!--          <use href="../assets/icons.svg#bars"></use>-->
      <!--        </svg>-->
      <span> Проект </span>
    </a>


    <div ref="menuElement" v-show="opened" class="fixed flex flex-col dropview p-[5px]  text-black square w-[200px] "
         bot-left
         style="z-index: 200; border-radius: 13px"
         @mouseleave="opened = false" @mouseover="opened = true">
      <button class="button itembutton px-4 " @click="load()">
        <Icon name="upload" color="black"></Icon>
        <!--        <svg class="svg">-->
        <!--          <use href="../assets/icons.svg#upload"></use>-->
        <!--        </svg>-->
        <span> Загрузить проект </span>
      </button>
      <button class="button itembutton  px-4  mt-1" @click="save()">
        <Icon name="check" color="black"></Icon>
        <!--        <svg class="svg">-->
        <!--          <use href="../assets/icons.svg#check"></use>-->
        <!--        </svg>-->
        <span> Сохранить проект </span>
      </button>
    </div>


  </div>
</template>

<script setup>

import {reactive, defineProps, ref, defineEmits, watch, nextTick} from "vue";
import Icon from "@/components/Icon";
import {useEditorStore} from "@/stores/editor";
import {usePalleteStore} from "@/stores/pallete";
import {usePopUpsStore} from "@/stores/popups";

const editorStore = useEditorStore()
const palleteStore = usePalleteStore()
const popUpsStore = usePopUpsStore()

const props = defineProps({
  modelValue: {
    default: false,
  }
})

const emit = defineEmits(["update:modelValue"])

const opened = ref(false)
const buttonElement = ref(null)
const menuElement = ref(null)

watch(() => props.modelValue, (v) => {
  opened.value = v
})

watch(() => opened.value, (v) => {
  emit('update:modelValue', v)
  if (v) {
    nextTick(() => {
      const rect = buttonElement.value.getBoundingClientRect();
      const computedButtonStyle = getComputedStyle(buttonElement.value);

      const buttonPaddingLeft = parseFloat(computedButtonStyle.paddingLeft);
      const buttonPaddingTop = parseFloat(computedButtonStyle.paddingTop);
      const buttonMarginLeft = parseFloat(computedButtonStyle.marginLeft);
      const buttonMarginTop = parseFloat(computedButtonStyle.marginTop);

      menuElement.value.style.left = `${rect.left + buttonPaddingLeft + buttonMarginLeft}px`;
      menuElement.value.style.top = `${rect.bottom - rect.top + buttonPaddingTop + buttonMarginTop + 10}px`;

      const menuRect = menuElement.value.getBoundingClientRect();
      const computedMenuStyle = getComputedStyle(menuElement.value);

      const menuPaddingRight = parseFloat(computedMenuStyle.paddingRight);
      const menuMarginRight = parseFloat(computedMenuStyle.marginRight);

      const windowWidth = window.innerWidth; // Ширина экрана
      const menuRightEdge = menuRect.right + menuPaddingRight + menuMarginRight;

      if (menuRightEdge >= windowWidth) {
        // Если menuElement выходит за правую границу экрана, выравниваем его
        const overflow = menuRightEdge - windowWidth;
        menuElement.value.style.left = `${rect.left - overflow - menuPaddingRight - menuMarginRight}px`;
      }
    })
  }
})


const data = reactive({
  showProjectMenu: false,
  isButtonHovered: false,
  switchState: 1,
  showPallete: true,
  items: [],
  input: ''
})

function load() {

  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = '.json';
  fileInput.style.display = 'none';
  fileInput.addEventListener('change', (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/json') {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const jsonObject = JSON.parse(e.target.result);
          editorStore.objects = []
          editorStore.objects = jsonObject
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      };
      reader.readAsText(file);
    } else {
      console.error('Please upload a valid JSON file.');
    }
  });
  document.body.appendChild(fileInput);
  fileInput.click();
  document.body.removeChild(fileInput);
}

function save() {
    popUpsStore.saveProject.visible = true
}
</script>

<style scoped>

</style>