<template>
  <div class="flex items-center justify-center min-h-screen bg-[#177e6c50] ">
    <div class="flex flex-col w-full max-w-md bg-white p-8 rounded-lg shadow">
      <h2 class="text-2xl font-bold text-center mb-6 ">Вход в систему</h2>
      <form @submit.prevent="handleSubmit">
        <div class="mb-4">
          <input 
            v-model="name" 
            class="form-input w-full px-4 py-2 border rounded-lg " 
            placeholder="Введите имя пользователя" 
            required
          />
        </div>
        <div class="mb-4">
          <input 
            v-model="password" 
            type="password" 
            class="form-input w-full px-4 py-2 border rounded-lg " 
            placeholder="Введите пароль" 
            required
          />
        </div>
        <div v-show=isVisible class="text-red-500 mb-3" >Аккаунт с такими учетными данными не найден</div>
        <div>
          <button 
            type="submit" 
            class="button button-positive w-full h-[40px] clipshape-all text-white "
          >
            Войти
          </button>
        </div>
      </form>
    </div>
  </div>
</template>


<script setup>
import { ref } from 'vue';
import router from "@/router";
import {useFetch} from "@/composables/useFetch";
import { useAuthStore } from '@/stores/useAuthStore';

const fetch = useFetch()
const authStore = useAuthStore()
let name = ref('')
let password = ref('')
let isVisible = ref(false)

const handleSubmit = () => {
  fetch.request(`/api/token/`, {
    method: 'POST',
    body: {
      username: name.value,
      password: password.value
    }
  })
  .then((data) => {
    console.log(data)
    authStore.login(data.access)
    router.push({ name: 'editorpage' })
  })
  .catch((error) => {
    console.error(error);
    isVisible.value = true
    name.value = ''
    password.value = ''
  })
};

</script>

<style scoped>

</style>