<template>
  <div v-if="props.modelValue" class="window-restyle-wrapper absolute inset-0 flex items-center justify-center" style="z-index: 1000" @wheel.stop>
    <div class="window window-restyle" style="filter: shadow;">
      <main class="window-background w-[400px]" style="flex-grow: 0;">
        <header class="window-title flex items-center">
				<span class="window-name font-normal flex-1" style="line-height: 1.3">
          <slot name="title">Попап</slot>
				</span>

          <a class="button button-transparent  top-0 right-0 m-[5px]" @click="close()">
            <Icon name="cross"></Icon>
          </a>
        </header>

        <div class="window-content overflow-y-auto space-y-[15px] max-h-[75vh]" style="flex: auto; flex-shrink: 1">
          <slot name="content">
            Содержимое попапа
          </slot>
          <div class="flex justify-end mt-5">
            <slot name="actions">

              <!-- <button @click="close()">Отмена</button> -->
              <button
                type="submit"
                class="button button-positive  clipshape-all text-white  px-5 mr-3"
                @click="confirm()"
              >Ок
              </button>
              <button class="button button-transparent clipshape-all px-5 " @click="cancel()">Отмена</button>
            </slot>

          </div>

        </div>

      </main>
    </div>
  </div>
</template>

<script setup>
import {ref, computed, defineProps, defineEmits} from 'vue';
import {usePalleteStore} from "@/stores/pallete";
import {useEditorStore} from "@/stores/editor";
import {usePopUpsStore} from "@/stores/popups";
import Icon from "@/components/Icon"; // Replace with actual import if needed

const palleteStore = usePalleteStore()
const editorStore = useEditorStore()
const popUpsStore = usePopUpsStore()

const props = defineProps({
  modelValue: { // Видим ли popup
    default: true,
  }
})

const emit = defineEmits([
  'update:modelValue',
  'cancel',
  'confirm',
])

const close = () => {
  emit('update:modelValue', false)
}

const cancel = () => {
  emit('cancel')
  close()

}

const confirm = () => {
  emit('confirm')
  close()
}


</script>


<style scoped></style>